import React from 'react'

import fromTealLogo from '../../assets/images/logo.png'

import classes from './Logo.css'


const logo = (props) => (
  <div className={'Logo'}>
    <img src={fromTealLogo} alt="fromTeal"/>
  </div>
)


export default logo
